import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);
  const iframeUrl = process.env.REACT_APP_STOREFRONT_IFRAME_URL;
  console.log(iframeUrl)

  useEffect(() => {
    // Function to update iframe height on window resize
    const updateHeight = () => {
      setIframeHeight(window.innerHeight);
    };

    // Set the initial height
    updateHeight();

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <div className="App">
      <iframe 
          src={iframeUrl}
          title="Lumi Storefront" 
          width="100%" 
          height={`${iframeHeight}px`}
          style={{ border: 'none' }}
          allowFullScreen
        ></iframe>
    </div>
  );
}

export default App;
